import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash-es';
import { useToggle } from 'react-use';
import type { ApplicationApiModel, BusinessApiModel, CreateBusinessModelApi, PersonApiModel, UpdateBusinessRequest } from '@lama/clients';
import { relatedBusinessesByIdsSelector } from '@lama/selectors';
import { ModifyItemButton } from '../ModifyItemButton';
import { AddOrEditRelatedCompanyDialog } from './AddOrEditRelatedCompanyDialog';
import { NoOwnerImage } from './NoOwnerImage';
import { RelatedCompanyListItem } from './RelatedCompanyListItem';

export interface RelatedCompaniesProps {
  loading?: boolean;
  application: ApplicationApiModel;
  business: BusinessApiModel;
  createBusiness: ({ business }: { business: CreateBusinessModelApi }) => Promise<void>;
  updateBusiness: ({
    businessId,
    updateBusinessPayload,
  }: {
    businessId: string;
    updateBusinessPayload: UpdateBusinessRequest;
  }) => Promise<void>;
}

type CreateRelatedCompanyRequest = CreateBusinessModelApi;
export type UpdateRelatedCompanyRequest = UpdateBusinessRequest & { id: string };
type RelatedCompany = CreateRelatedCompanyRequest | UpdateRelatedCompanyRequest;

interface Owner {
  person?: PersonApiModel;
  business?: BusinessApiModel;
  ownershipPercentage: number;
}
export type RelatedCompanyWithOwner = RelatedCompany & { owner: Owner };

export const RelatedCompanies: React.FC<RelatedCompaniesProps> = ({ application, business, createBusiness, updateBusiness, loading }) => {
  const { t } = useTranslation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [initialDialogValues, setInitialDialogValues] = useState<RelatedCompany | null>(null);
  const [noRelatedCompanies, toggleNoRelatedCompanies] = useToggle(!!business?.markedNoRelatedCompanies);

  const relatedCompanies = useMemo(() => {
    if (!business) {
      return [];
    }

    const sisterCompanies = relatedBusinessesByIdsSelector(application, business.sisterCompaniesIds).map((b) => b.business);
    const subsidiaries = relatedBusinessesByIdsSelector(application, business.subsidiariesIds).map((b) => b.business);

    return [...sisterCompanies, ...subsidiaries];
  }, [application, business]);

  const onAddBusinessClick = useCallback(() => {
    setInitialDialogValues(null);
    setDialogOpen(true);
  }, []);

  // To be deleted after we change the ownershipPercentage not to be on the owner but only on the owned business in 'owningBusinesses'
  const setOwnershipOnOwningBusiness = useCallback(
    async (ownerId: string, ownershipPercentage: number) => {
      await updateBusiness({ businessId: ownerId, updateBusinessPayload: { ownershipPercentage } });
    },
    [updateBusiness],
  );

  const setOwnershipOnOwnedBusiness = useCallback(
    async ({ owner, ownedBusiness }: { owner: Owner | null; ownedBusiness: RelatedCompany }) => {
      if (!business) {
        return;
      }

      // 'relatedBusinesses' updates should be deleted after fully removed affiliates.
      const ownershipPayload = {
        ...(owner?.person ? { people: [{ ...owner.person, ownershipPercentage: owner.ownershipPercentage }] } : { people: [] }),
        ...(owner?.business
          ? {
              owningBusinesses: [{ id: owner.business.id, ownershipPercentage: owner.ownershipPercentage }],
              relatedBusinesses: uniq([...(ownedBusiness.relatedBusinesses ?? []), business.id]),
            }
          : {
              owningBusinesses: [],
              relatedBusinesses: (ownedBusiness.relatedBusinesses ?? []).filter((id) => id !== business.id),
            }),
      };

      await updateBusiness({ businessId: ownedBusiness.id, updateBusinessPayload: ownershipPayload });
    },
    [updateBusiness, business],
  );

  const updateOriginBusiness = useCallback(
    async (updatePayload: UpdateBusinessRequest) => {
      if (!business) {
        return;
      }

      await updateBusiness({ businessId: business.id, updateBusinessPayload: updatePayload });
    },
    [business, updateBusiness],
  );

  const addRelatedCompanyToOriginBusiness = useCallback(
    async (relatedCompanyId: string) => {
      await updateOriginBusiness({
        relatedBusinesses: uniq([...(business?.relatedBusinesses ?? []), relatedCompanyId]),
      });
    },
    [business, updateOriginBusiness],
  );

  const removeRelatedCompanyFromOriginBusiness = useCallback(
    async (relatedCompanyId: string) => {
      await updateOriginBusiness({
        relatedBusinesses: (business?.relatedBusinesses ?? []).filter((id) => id !== relatedCompanyId),
      });
    },
    [business, updateOriginBusiness],
  );

  const assignOwnership = useCallback(
    async ({ ownedBusiness, owner }: { ownedBusiness: UpdateRelatedCompanyRequest; owner: Owner }) => {
      await setOwnershipOnOwnedBusiness({ owner, ownedBusiness });

      if (owner.business) {
        await setOwnershipOnOwningBusiness(owner.business.id, owner.ownershipPercentage);
      }
    },
    [setOwnershipOnOwningBusiness, setOwnershipOnOwnedBusiness],
  );

  const removeOwnership = useCallback(
    async ({ ownedBusiness, owningBusinessId }: { ownedBusiness: UpdateRelatedCompanyRequest; owningBusinessId?: string }) => {
      await setOwnershipOnOwnedBusiness({ owner: null, ownedBusiness });

      if (owningBusinessId) {
        await setOwnershipOnOwningBusiness(owningBusinessId, 0);
      }
    },
    [setOwnershipOnOwningBusiness, setOwnershipOnOwnedBusiness],
  );

  const editBusiness = useCallback(
    async ({ ownedBusiness, owner }: { ownedBusiness: UpdateRelatedCompanyRequest; owner: Owner }) => {
      await updateBusiness({ businessId: ownedBusiness.id, updateBusinessPayload: ownedBusiness });
      await assignOwnership({ owner, ownedBusiness: { id: ownedBusiness.id } });
    },
    [updateBusiness, assignOwnership],
  );

  const addBusiness = useCallback(
    async ({ ownedBusiness, owner }: { ownedBusiness: CreateRelatedCompanyRequest; owner: Owner }) => {
      await createBusiness({ business: { ...ownedBusiness, people: [] } });
      await addRelatedCompanyToOriginBusiness(ownedBusiness.id);

      await assignOwnership({ owner, ownedBusiness: { id: ownedBusiness.id } });
    },
    [createBusiness, assignOwnership, addRelatedCompanyToOriginBusiness],
  );

  const onEditBusinessCard = useCallback(
    (id: string) => {
      const businessToEdit = application.relatedBusinesses.find((relatedBusiness) => relatedBusiness.business.id === id)?.business ?? null;
      setInitialDialogValues(businessToEdit);
      setDialogOpen(true);
    },
    [application.relatedBusinesses],
  );

  const onDeleteBusinessCard = useCallback(
    async (businessId: string) => {
      const businessToDelete = relatedCompanies.find(({ id }) => id === businessId);
      if (!businessToDelete) {
        return;
      }

      await removeOwnership({
        owningBusinessId: businessToDelete?.owningBusinesses?.[0]?.id,
        ownedBusiness: { id: businessId },
      });
      await removeRelatedCompanyFromOriginBusiness(businessId);
    },
    [relatedCompanies, removeOwnership, removeRelatedCompanyFromOriginBusiness],
  );

  const onNoRelatedCompaniesMarkClick = useCallback(async () => {
    if (!business) {
      return;
    }

    toggleNoRelatedCompanies();
    await updateOriginBusiness({ markedNoRelatedCompanies: !noRelatedCompanies });
  }, [toggleNoRelatedCompanies, business, noRelatedCompanies, updateOriginBusiness]);

  const handleClose = useCallback(
    async (ownedBusiness: RelatedCompanyWithOwner | null) => {
      if (ownedBusiness) {
        const existingBusiness = application.relatedBusinesses.find(({ business: { id } }) => id === ownedBusiness?.id)?.business;
        const { owner, ...businessWithoutOwner } = ownedBusiness;

        await (existingBusiness
          ? editBusiness({ ownedBusiness: businessWithoutOwner as UpdateRelatedCompanyRequest, owner })
          : addBusiness({ ownedBusiness: businessWithoutOwner as CreateRelatedCompanyRequest, owner }));
      }

      setDialogOpen(false);
    },
    [addBusiness, application.relatedBusinesses, editBusiness],
  );

  if (!business) {
    return null;
  }

  return (
    <>
      <Stack alignItems={'center'} flex={1} gap={4} height={'100%'}>
        {relatedCompanies.length ? (
          relatedCompanies?.map((company) => (
            <RelatedCompanyListItem
              key={company.id}
              application={application}
              business={business}
              ownedBusiness={company}
              onDelete={onDeleteBusinessCard}
              onEdit={onEditBusinessCard}
            />
          ))
        ) : (
          <Stack width={'100%'} height={'100%'} alignItems={'center'}>
            <NoOwnerImage />
          </Stack>
        )}
        <ModifyItemButton onClick={onAddBusinessClick} text={t('affiliates.cta.add')} disabled={noRelatedCompanies} variant={'text'} />
        {!relatedCompanies.length ? (
          <FormControlLabel
            label={'No related companies'}
            control={<Checkbox checked={noRelatedCompanies} onChange={onNoRelatedCompaniesMarkClick} />}
          />
        ) : null}
      </Stack>
      <AddOrEditRelatedCompanyDialog
        application={application}
        open={dialogOpen}
        handleClose={handleClose}
        initialValues={initialDialogValues}
        isLoading={loading}
        businessId={business.id}
      />
    </>
  );
};
