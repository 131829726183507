import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { compact } from 'lodash-es';
import { Divider, Grid } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { v4 as uuidv4 } from 'uuid';
import { type ApplicationApiModel } from '@lama/clients';
import { businessLegalNameValidation, businessTinValidation, zipCodeValidation } from '@lama/yup-validations';
import { owningBusinessesSelectorV2, ownersSelector, personFullName } from '@lama/selectors';
import { Flex } from '@lama/design-system';
import type { LabeledValue } from '@lama/contracts';
import { BaseDialog, type DialogMode } from '../BaseDialog';
import { FormikPicker, FormikStatePicker, PropertyFormikInput } from '../FormikFields';
import { ModifyItemButton } from '../ModifyItemButton';
import type { RelatedCompanyWithOwner, UpdateRelatedCompanyRequest } from './RelatedCompanies';

interface AddOrEditDialogProps {
  open: boolean;
  handleClose: (values: RelatedCompanyWithOwner | null) => void;
  initialValues: UpdateRelatedCompanyRequest | null;
  businessId: string;
  isLoading?: boolean;
  application: ApplicationApiModel;
}

interface BusinessFormValues {
  legalName: string;
  owner: string;
  tin: string;
  address1?: string;
  city?: string;
  state?: string;
  zip?: string;
}

const emptyAffiliate = {
  legalName: '',
  owner: '',
  tin: '',
  address1: '',
  city: '',
  state: '',
  zip: '',
};

const validationSchema = yup.object({
  legalName: businessLegalNameValidation.required('Legal Name is required'),
  tin: businessTinValidation,
  zip: zipCodeValidation,
});

export const AddOrEditRelatedCompanyDialog: React.FC<AddOrEditDialogProps> = ({
  open,
  handleClose,
  initialValues,
  isLoading,
  application,
  businessId,
}) => {
  const { t } = useTranslation();
  const business = useMemo(
    () => application.relatedBusinesses.find((b) => b.business.id === businessId)?.business,
    [application.relatedBusinesses, businessId],
  );

  const owners = useMemo(
    () => ({
      businesses: business ? owningBusinessesSelectorV2({ application, business }).map((b) => b.owner) : [],
      people: business ? ownersSelector(business) ?? [] : [],
    }),
    [application, business],
  );

  const mode: DialogMode = initialValues ? 'edit' : 'add';

  const getOwnerLabel = useCallback(
    (ownerId: string) => {
      if (!ownerId) {
        return '';
      }

      const owningPerson = owners.people.find(({ id }) => id === ownerId);
      if (owningPerson) {
        return personFullName(owningPerson);
      }

      const owningBusiness = compact([business, ...owners.businesses]).find(({ id }) => id === ownerId);

      return owningBusiness?.legalName ?? '';
    },
    [business, owners.businesses, owners.people],
  );

  const ownersOptions: LabeledValue[] = useMemo(
    () =>
      [
        ...Object.values(owners).flatMap((owningPeopleOrBusinesses) => owningPeopleOrBusinesses.map(({ id }) => id)),
        ...(business ? [business.id] : []),
      ].map((id) => ({
        value: id,
        label: getOwnerLabel(id),
      })),
    [business, getOwnerLabel, owners],
  );

  const onSubmit = useCallback(
    (values: BusinessFormValues) => {
      const ownerId = values.owner;

      const owningPerson = owners.people.find(({ id }) => id === ownerId);
      const owningBusiness = owners.businesses.find(({ id }) => id === ownerId) ?? business;

      if (!owningBusiness) {
        return;
      }

      const valuesToSubmit: RelatedCompanyWithOwner = {
        id: initialValues?.id ?? uuidv4(),
        legalName: values.legalName,
        tin: values.tin,
        addresses: [
          {
            address1: values.address1,
            city: values.city,
            state: values.state,
            zip: values.zip,
            country: 'US',
          },
        ],
        owner: {
          ...(owningPerson ? { person: owningPerson } : { business: owningBusiness }),
          ownershipPercentage: 50,
        },
      };

      handleClose(valuesToSubmit);
    },
    [business, handleClose, initialValues?.id, owners.businesses, owners.people],
  );

  const initialValuesInner: BusinessFormValues = useMemo(
    () =>
      initialValues
        ? {
            id: initialValues.id ?? uuidv4(),
            legalName: initialValues.legalName ?? '',
            tin: initialValues.tin ?? '',
            owner: initialValues.owningBusinesses?.[0]?.id ?? initialValues.people?.[0]?.id ?? '',
            ...(initialValues.addresses?.[0] ?? { state: '', country: '', city: '', zip: '', address1: '' }),
          }
        : emptyAffiliate,
    [initialValues],
  );

  return (
    <BaseDialog open={open} onClose={handleClose} title={t(`affiliates.dialog.title.${mode}`)}>
      <Formik validationSchema={validationSchema} initialValues={initialValuesInner} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <PropertyFormikInput name={'legalName'} label={'Legal Name'} required fullWidth />
            </Grid>
            <Grid item md={6} xs={12}>
              <PropertyFormikInput name={'tin'} label={'Tax ID'} fullWidth />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormikPicker name={'owner'} label={'Owned By'} values={ownersOptions} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <PropertyFormikInput name={'address1'} label={'Address Line'} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <PropertyFormikInput name={'city'} label={'City'} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikStatePicker name={'state'} label={'State'} fullWidth />
            </Grid>
            <Grid item xs={12} md={6}>
              <PropertyFormikInput name={'zip'} label={'Zip Code'} fullWidth />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} marginTop={2}>
              <Flex justifyContent={'center'}>
                <ModifyItemButton onClick={handleSubmit} text={t(`principals.dialog.cta.${mode}`)} showIcon={false} loading={isLoading} />
              </Flex>
            </Grid>
          </Grid>
        )}
      </Formik>
    </BaseDialog>
  );
};
